export const EMOTIONS = {
    happy: 1,
    sad: 2,
    angry: 3,
    sick: 4,
    peeled: 5
}

export const SIZES = {
    icon: 1,
    small: 2,
    smallcropped: 3,
    medium: 4,
    large: 5,
    face: 6,
    largest: 7,
    // there is no 8, weird isn't it
    cropped: 9,
}
